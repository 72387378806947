import React from 'react';
import { motion } from 'framer-motion';
import { pageTransition, pageVariants } from '../helpers/pageTransition';
import ProgressBar from '../components/ProgressBar';

class Resume extends React.Component {
	render() {
		return (
			<motion.div
				initial="out"
				animate="in"
				exit="out"
				variants={pageVariants}
				transition={pageTransition}
			>
				<div className="text-left text-white m-10">
					<p className="text-4xl pb-2">
						My <span className="text-sky-700">Resume</span>
					</p>
					<div className="grid md:grid-cols-2 gap-10 ">
						<div>
							<p className="text-2xl py-5">Experience</p>
							<ol className="relative border-l border-gray-200 dark:border-sky-700">
								<li className="mb-10 ml-4">
									<div className="absolute w-3 h-3 bg-gray-200 rounded-full -left-1.5 border border-white "></div>
									<time className="mb-1 text-sm font-normal leading-none text-gray-400">
										2022/08 – present
									</time>
									<h3 className="text-lg font-semibold text-white">
										Systems programmer
									</h3>
									<h3 className="text-m font-semibold text-sky-700">
										Combitech AB
									</h3>
									<p className="mb-4 text-base font-normal text-gray-500">
										System programmer at Skövde.
									</p>
								</li>
								<li className="mb-10 ml-4">
									<div className="absolute w-3 h-3 bg-gray-200 rounded-full -left-1.5 border border-white "></div>
									<time className="mb-1 text-sm font-normal leading-none text-gray-400">
										2021/09 – 2022/06
									</time>
									<h3 className="text-lg font-semibold text-white">
										Flygbassäkerhetssoldat
									</h3>
									<h3 className="text-m font-semibold text-sky-700">
										Försvarsmakten
									</h3>
									<p className="mb-4 text-base font-normal text-gray-500">
										Military service in the Swedish Air
										Force as a conscript.
									</p>
								</li>
								<li className="mb-10 ml-4">
									<div className="absolute w-3 h-3 bg-gray-200 rounded-full -left-1.5 border border-white "></div>
									<time className="mb-1 text-sm font-normal leading-none text-gray-400">
										2021/02 – 2022/08
									</time>
									<h3 className="text-lg font-semibold text-white">
										IT Consultant
									</h3>
									<h3 className="text-m font-semibold text-sky-700">
										Netred AB / Monitor ERP System AB
									</h3>
									<p className="mb-4 text-base font-normal text-gray-500">
										Developing adapations to Monitor ERP
										system.
									</p>
								</li>
							</ol>
							<p className="text-2xl py-5">Education</p>
							<ol className="relative border-l border-gray-200 dark:border-sky-700">
								<li className="mb-10 ml-4">
									<div className="absolute w-3 h-3 bg-gray-200 rounded-full -left-1.5 border border-white "></div>
									<time className="mb-1 text-sm font-normal leading-none text-gray-400">
										2019 – 2020
									</time>
									<h3 className="text-lg font-semibold text-white">
										Gymnasieingenjör
									</h3>
									<h3 className="text-m font-semibold text-sky-700">
										NTI Gymnasiet Skövde
									</h3>
									<p className="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">
										Gymnasieingenjörsprogrammet (TE4) is an
										extension year to the technology
										program. During one year, you immerse
										yourself in software development, run
										your own projects and have a 10-week
										internship. Then you graduate as a
										certified upper secondary school
										engineer.
									</p>
								</li>
								<li className="mb-10 ml-4">
									<div className="absolute w-3 h-3 bg-gray-200 rounded-full -left-1.5 border border-white "></div>
									<time className="mb-1 text-sm font-normal leading-none text-gray-400">
										2016 – 2019
									</time>
									<h3 className="text-lg font-semibold text-white">
										The technology program
									</h3>
									<h3 className="text-m font-semibold text-sky-700">
										Rudbecksgymnasiet Tidaholm
									</h3>
									<p className="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">
										The focus is on computer communication
										in information, communication and media
										technology. You get to learn programming
										and how to make a modern website that is
										dynamic and interactive and read the
										courses web development and web server
										programming.
									</p>
								</li>
							</ol>
						</div>
						<div>
							<p className="text-2xl py-5">
								Design{' '}
								<span className="text-sky-700">Skills</span>
							</p>
							<ProgressBar title="Web Design" percentage={95} />
							<ProgressBar
								title="Graphic Design"
								percentage={70}
							/>
							<ProgressBar title="Logo Design" percentage={65} />
							<p className="text-2xl py-5">
								Coding{' '}
								<span className="text-sky-700">Skills</span>
							</p>
							<ProgressBar title="C++" percentage={70} />
							<ProgressBar title="C#" percentage={80} />
							<ProgressBar title="JavaScript" percentage={85} />
							<ProgressBar title="Python" percentage={90} />
							<ProgressBar title="HTML/CSS" percentage={100} />
							<p className="text-2xl py-5">Knowledges</p>
							<span className="py-1 px-3 mr-2 bg-sky-700 rounded-lg text-xs font-bold">
								Problem-Solving
							</span>
							<span className="py-1 px-3 mr-2 bg-sky-700 rounded-lg text-xs font-bold">
								Flexibility
							</span>
							<span className="py-1 px-3 mr-2 bg-sky-700 rounded-lg text-xs font-bold">
								Communication
							</span>
							<span className="py-1 px-3 mr-2 bg-sky-700 rounded-lg text-xs font-bold">
								Time Management
							</span>
							<p className="text-2xl py-5">Certificates</p>
							<div>
								<div className="p-5 bg-neutral-800 rounded-lg border-2 border-orange-300">
									<p className="pt-2">
										Cambridge English, Proficient user C1
									</p>
									<p className="text-sm py-1 text-gray-400">
										Cambridge English Qualifications are
										internationally accepted diplomas in
										English. In Sweden, the examinations are
										conducted by Folkuniversitetet in close
										collaboration with the unit Cambridge
										Assessment English at the University of
										Cambridge.
									</p>
								</div>
							</div>
							<div>
								<div className="p-5 bg-neutral-800 rounded-lg border-2 border-orange-300 mt-5">
									<p className="pt-2">
										Matematik – specialisering, Linjär
										algebra
									</p>
									<p className="text-sm py-1 text-gray-400">
										Mathematics - specialization is a course
										in high school that prepares students
										for university studies in mathematics.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</motion.div>
		);
	}
}

export default Resume;
