export default function ProgressBar(props) {
	return (
		<div>
			<div className="flex justify-between my-1">
				<span className="text-base font-medium text-blue-700 dark:text-white">
					{props.title}
				</span>
				<span className="text-sm font-medium text-blue-700 dark:text-white">
					{props.percentage}%
				</span>
			</div>
			<div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
				<div className="bg-blue-600 h-2.5 rounded-full" style={{width: props.percentage + '%'}}></div>
			</div>
		</div>
	);
}
