import React from 'react';

class BackgroundAnimaiton extends React.Component {
    render() {
      return (
        <div className="area" >
            <ul className="circles">
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
            </ul>
        </div >
      )
    }
  }
  
  export default BackgroundAnimaiton;