
import React from 'react';
import { motion } from 'framer-motion';
import { pageTransition, pageVariants } from '../helpers/pageTransition';
import { BsGithub } from "react-icons/bs";
import edgusdevimg from '../assets/edgusdev.png';

class Portfolio extends React.Component {
  render() {

    return (
      <motion.div initial="out" animate="in" exit="out" variants={pageVariants} transition={pageTransition}>
        <div className='text-left text-white m-10'>
            <p className='text-4xl pb-2'>My <span className='text-sky-700'>Portfolio</span></p>
            <div className="grid md:grid-cols-4 gap-4 mt-5">
                <div className="p-5 bg-neutral-800 rounded-lg cursor-pointer transition ease-in-out delay-50 hover:-translate-y-1 hover:scale-110 hover:bg-sky-700 duration-300">
                    <a href='https://github.com/EddieGustafsson/Error-Code-Organizer' target="_blank" rel="noreferrer">
                        <img src="https://i.imgur.com/tMM0uIv.png" alt='' className='object-fit w-[230] h-[100] rounded-lg' />
                        <p className='pt-2'>Error Code Organizer</p>
                        <p className='text-sm py-1 text-gray-400'>MERN based application to organize error codes in a project.</p>
                        <div>
                            <span className='py-1 px-3 mr-2 bg-neutral-900 rounded-lg text-xs font-bold'>MERN</span>
                            <span className='py-1 px-3 mr-2 bg-neutral-900 rounded-lg text-xs font-bold'>Redux</span>
                            <span className='py-1 px-3 mr-2 bg-neutral-900 rounded-lg text-xs font-bold'>JWT</span>
                        </div>
                    </a>
                </div>
                <div className="p-5 bg-neutral-800 rounded-lg cursor-pointer transition ease-in-out delay-50 hover:-translate-y-1 hover:scale-110 hover:bg-sky-700 duration-300">
                    <a href='https://github.com/EddieGustafsson/wiki' target="_blank" rel="noreferrer">
                        <img src="https://i.imgur.com/VpEMQc4.png" alt='' className='object-fit w-[230px] h-[100px] rounded-lg'  />
                        <p className='pt-2'>Wiki</p>
                        <p className='text-sm py-1 text-gray-400'>Knowledge base website, a wiki, on which users collaboratively modify content and structure directly from the web browser</p>
                        <div>
                            <span className='py-1 px-3 mr-2 bg-neutral-900 rounded-lg text-xs font-bold'>PHP</span>
                            <span className='py-1 px-3 mr-2 bg-neutral-900 rounded-lg text-xs font-bold'>MySQL</span>
                            <span className='py-1 px-3 mr-2 bg-neutral-900 rounded-lg text-xs font-bold'>REST</span>
                        </div>
                    </a>
                </div>
                <div className="p-5 bg-neutral-800 rounded-lg cursor-pointer transition ease-in-out delay-50 hover:-translate-y-1 hover:scale-110 hover:bg-sky-700 duration-300">
                    <a href='https://github.com/EddieGustafsson/eddiegustafsson.github.io' target="_blank" rel="noreferrer">
                        <img src={edgusdevimg} alt='' className='object-fit w-[230px] h-[100px] rounded-lg'  />
                        <p className='pt-2'>My personal website</p>
                        <p className='text-sm py-1 text-gray-400'>My personal website featuring an about me page, portfolio and resume.</p>
                        <div>
                            <span className='py-1 px-3 mr-2 bg-neutral-900 rounded-lg text-xs font-bold'>REACT</span>
                            <span className='py-1 px-3 mr-2 bg-neutral-900 rounded-lg text-xs font-bold'>Tailwind CSS</span>
                        </div>
                    </a>
                </div>
                <div className="p-5 bg-neutral-800 rounded-lg cursor-pointer transition ease-in-out delay-50 hover:-translate-y-1 hover:scale-110 hover:bg-sky-700 duration-300 text-center">
                    <a href='https://github.com/EddieGustafsson' target="_blank" rel="noreferrer">
                      <BsGithub className='w-20 h-20 text-neutral-700 m-auto'/>
                      <p className='pt-2'>Find more on my Github profile</p>
                    </a>
                </div>
            </div>
        </div>
      </motion.div>
    )
  }
}

export default Portfolio;