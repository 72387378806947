import avatar from '../assets/avatar.png';
import {
	BsLinkedin,
	BsGithub,
} from 'react-icons/bs';
import {FaGitlab} from 'react-icons/fa';

export default function InfoHeaderPanel() {
  return (
    <div
    className="bg-neutral-800 rounded-[32px] flex-none pr-20 hidden md:block" 
>
    <div className="p-5">
        <div>
            <img
                src={avatar}
                className="w-24 h-24 rounded-full inline drop-shadow-lg"
                alt="avatar"
            />
            <p className="text-2xl text-white">
                Eddie Gustafsson
            </p>
            <p className="text-neutral-400">
                Systems Programmer
            </p>
            <div className="flex flex-row justify-center">
                <div className="p-2">
                    <a href="https://gitlab.com/EddieGustafsson" target="_blank" rel="noreferrer">
                        <FaGitlab
                            className='text-white hover:text-sky-700 transition ease-in-out delay-50'
                        />
                    </a>
                </div>
                <div className="p-2">
                    <a href="https://www.linkedin.com/in/eddiegustafsson/" target="_blank" rel="noreferrer">
                        <BsLinkedin
                            className='text-white hover:text-sky-700 transition ease-in-out delay-50'
                        />
                    </a>
                </div>
                <div className="p-2">
                    <a href="https://github.com/EddieGustafsson" target="_blank" rel="noreferrer">
                        <BsGithub
                            className='text-white hover:text-sky-700 transition ease-in-out delay-50'
                        />
                    </a>
                </div>
            </div>
        </div>
        <div className="p-10">
            <button
                type="button"
                className="text-white transition ease-in-out delay-50 hover:text-black border border-white hover:bg-white focus:ring-4 focus:ring-blue-300 font-medium rounded-[32px] text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:border-white dark:text-white dark:hover:text-black dark:hover:bg-white dark:focus:ring-white"
            >
                Download CV
            </button>
        </div>
        <div className="bottom-0">
            <p
                className="text-neutral-400 text-sm mb-3 ml-5"
                style={{
                    bottom: 0,
                    position: 'absolute',
                }}
            >
                © 2024 All rights reserved.
            </p>
        </div>
    </div>
</div>
  );
}