import React from 'react';
import { motion } from 'framer-motion';
import { pageTransition, pageVariants } from '../helpers/pageTransition';

class Contact extends React.Component {
	render() {
		return (
			<motion.div
				initial="out"
				animate="in"
				exit="out"
				variants={pageVariants}
				transition={pageTransition}
			>
				<div className="text-left text-white m-10">
					<p className="text-4xl pb-2">
						Contact <span className="text-sky-700">Me</span>
					</p>
					<div>
						<p className="py-2">
							Whether you have a question or just want to say hi,
							I'll try my best to get back to you!
						</p>
					</div>
					<div>
						<form
							action="https://api.staticforms.xyz/submit"
							method="post"
						>
							<input
								type="hidden"
								name="accessKey"
								value="343a9f29-756d-4c41-baa0-5666214b82e4"
							/>
							<input
								type="hidden"
								name="subject"
								value="Contact form - edgus.dev"
							/>
							<input
								type="text"
								name="honeypot"
								style={{ display: 'none' }}
							/>

							<div className="w-full md:w-1/2 my-2">
								<label
									className="block uppercase tracking-wide text-sky-700 text-xs font-bold mb-2"
									htmlFor="email"
								>
									Email Address
								</label>
								<input
									className="appearance-none block w-full bg-neutral-600  text-white  border border-neutral-900 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-neutral-500 focus:border-gray-500 focus:text-white"
									name="email"
									type="email"
									required
									placeholder="firstname.lastname@yourdomain.com"
								/>
							</div>
							<div className="w-full md:w-1/2 mb-2">
								<label
									className="block uppercase tracking-wide text-sky-700 text-xs font-bold mb-2"
									htmlFor="name"
								>
									Your name
								</label>
								<input
									className="appearance-none block w-full bg-neutral-600  text-white  border border-neutral-900 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-neutral-500 focus:border-gray-500 focus:text-white"
									name="name"
									type="text"
									required
									placeholder="First Last"
								/>
							</div>
							<div className="w-full md:w-1/2 mb-2">
								<label
									className="block uppercase tracking-wide text-sky-700 text-xs font-bold mb-2"
									htmlFor="message"
								>
									Your message
								</label>
								<textarea
									className="appearance-none block w-full bg-neutral-600  text-white border border-neutral-900 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-neutral-500 focus:border-gray-500 focus:text-white"
									name="message"
									required
									placeholder="Your message..."
								/>
							</div>
							<input
								type="hidden"
								name="redirectTo"
								value="https://edgus.dev/#/contact/success"
							/>
							<input
								className="mt-2 text-white transition ease-in-out delay-50 hover:text-black border border-sky-700 hover:bg-sky-700 focus:ring-4 focus:ring-blue-300 font-medium rounded-[32px] text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:border-sky-700 dark:text-white dark:hover:text-white dark:hover:bg-sky-700 dark:focus:ring-white cursor-pointer"
								type="submit"
								value="Send your message"
							/>
						</form>
					</div>
				</div>
			</motion.div>
		);
	}
}

export default Contact;
