import {
	FaUserAlt,
	FaHome,
	FaEnvelope,
	FaChevronLeft,
	FaChevronRight,
	FaClipboardList,
} from 'react-icons/fa';
import { BsFillBriefcaseFill } from 'react-icons/bs';
import { NavLink, useHistory, useLocation } from 'react-router-dom';

const SideBarIcon = ({ icon, text = 'tooltip 💡', link }) => (
	<div className='flex-auto'>
		<NavLink exact to={link} activeClassName="active">
			<div className="sidebar-icon group">
				{icon}
				<span className="sidebar-tooltip group-hover:scale-100">
					{text}
				</span>
			</div>
		</NavLink>
	</div>
);

function NavBar() {
	const history = useHistory();
	const location = useLocation();

	const forwardUrl = (() => {
		switch (location.pathname) {
			case '/':
				return '/about';
			case '/about':
				return '/resume';
			case '/resume':
				return '/portfolio';
			case '/portfolio':
				return '/contact';
			case '/contact':
				return '/';
			default:
				return '/';
		}
	})();

	const backwardUrl = (() => {
		switch (location.pathname) {
			case '/':
				return '/contact';
			case '/about':
				return '/';
			case '/resume':
				return '/about';
			case '/portfolio':
				return '/resume';
			case '/contact':
				return '/portfolio';
			default:
				return '/';
		}
	})();

	return (
		<div className="fixed mt-[-5rem] bottom-2 w-full md:w-auto md:top-0 md:relative md:ml-4 md:mt-0">
			<div className="bg-neutral-800 rounded-[32px] p-2 md:p-4 md:top-20 flex flex-row md:flex-col">
				<SideBarIcon icon={<FaHome />} text="Home" link="/" />
				<SideBarIcon icon={<FaUserAlt />} text="About" link="/about" />
				<SideBarIcon
					icon={<FaClipboardList />}
					text="Resume"
					link="/resume"
				/>
				<SideBarIcon
					icon={<BsFillBriefcaseFill />}
					text="Portfolio"
					link="/portfolio"
				/>
				<SideBarIcon
					icon={<FaEnvelope />}
					text="Contact"
					link="/contact"
				/>
			</div>
			<div
				className="bg-neutral-800 rounded-[32px] p-4 hidden md:block"
				style={{ bottom: 0, position: 'absolute' }}
			>
				<ul className="list-none">
					<li>
						<div
							className="sidebar-icon group"
							onClick={() => {
								history.push(backwardUrl);
							}}
						>
							<FaChevronLeft className="nav-link" />
							<span className="sidebar-tooltip group-hover:scale-100">
								Back
							</span>
						</div>
					</li>
					<li>
						<div
							className="sidebar-icon group"
							onClick={() => {
								history.push(forwardUrl);
							}}
						>
							<FaChevronRight className="nav-link" />
							<span className="sidebar-tooltip group-hover:scale-100">
								Forward
							</span>
						</div>
					</li>
				</ul>
			</div>
		</div>
	);
}

export default NavBar;
