import avatar from '../assets/avatar.png';

function MobileTopBar() {

	return (
		<div className="fixed w-full top-2 md:hidden">
			<div className="bg-neutral-800 rounded-[32px] p-2 md:p-4 md:top-20 flex flex-row md:flex-col">
                <div className="flex-1">
                    <p className='text-right text-xl uppercase font-bold text-sky-700 tracking-wider p-2'>Edgus</p>
                </div>
                <div className="flex-1">
                    <img
                        src={avatar}
                        className="w-12 h-12 rounded-full inline"
                        alt="avatar"
                    />
                </div>
                <div className="flex-1">
                    <p className='text-left text-xl uppercase font-bold text-sky-700 tracking-wider p-2'>dev</p>
                </div>
			</div>
		</div>
	);
}

export default MobileTopBar;
