
import React from 'react';
import { motion } from 'framer-motion';
import { pageTransition, pageVariants } from '../helpers/pageTransition';

class ContactSuccess extends React.Component {
  render() {

    return (
      <motion.div initial="out" animate="in" exit="out" variants={pageVariants} transition={pageTransition}>
        <div className='text-left text-white m-10'>
            <p className='text-4xl pb-2'>Message <span className='text-sky-700'>sent!</span></p>
            <p>Your message has been sent successfully, I hope to respond within 24 hours.</p>
        </div>
      </motion.div>
    )
  }
}

export default ContactSuccess;