
import React from 'react';
import { motion } from 'framer-motion';
import { pageTransition, pageVariants} from '../helpers/pageTransition';
import JSONPretty from 'react-json-pretty';
import PackageJson from '../edgus.json';

var JSONPrettyTheme = {
  main: 'line-height:1.3;color:#f8f8f2;overflow:auto;',
  error: 'line-height:1.3;color:#f8f8f2;overflow:auto;',
  key: 'color:#0369A1;',
  string: 'color:#e9fdac;',
  value: 'color:#fdb082;',
  boolean: 'color:#69c;',
}

class Home extends React.Component {
  render() {

    return (
      <motion.div initial="out" animate="in" exit="out" variants={pageVariants} transition={pageTransition}>
        <div className='m-5 md:m-10 text-left'>
          <p className='text-white text-5xl font-bold'>Eddie Gustafsson</p>
          <p className='text-white text-2xl font-light'>Systems Programmer</p>
          <div className='mt-5'>
            <JSONPretty data={PackageJson} theme={JSONPrettyTheme} className='text-xs md:text-base'></JSONPretty>
          </div>
        </div>
      </motion.div>
    )
  }
}

export default Home;