import { Switch, Route, withRouter } from "react-router-dom";
import { AnimatePresence } from 'framer-motion';
import Home from '../pages/Home';
import About from '../pages/About';
import Contact from "../pages/Contact";
import Resume from "../pages/Resume";
import Portfolio from "../pages/Portfolio";
import ContactSuccess from "../pages/ContactSuccess";

function MainContainer({ location }) {
  return (
    <div className="bg-zinc-900 rounded-[32px] md:grow md:ml-[-80px]">
      <section className="scrollbar-thin scrollbar-thumb-neutral-600 scrollbar-track-zinc-800 scrollbar-thumb-rounded-full scrollbar-track-rounded-full mx-5 mt-30 md:m-5" style={{ minHeight: '80vh', maxHeight: '80vh', overflow: 'auto' }}>
        <AnimatePresence exitBeforeEnter>
          <Switch location={location}>
              <Route exact path="/"  component={Home}/>
              <Route exact path="/about" component={About}/>
              <Route exact path="/resume" component={Resume}/>
              <Route exact path="/contact" component={Contact}/>
              <Route exact path="/contact/success" component={ContactSuccess}/>
              <Route exact path="/portfolio" component={Portfolio}/>
          </Switch>
        </AnimatePresence>
      </section>
    </div>
  );
}

export default withRouter(MainContainer);