import './App.css';

import MainContainer from './components/MainContainer';
import { HashRouter } from 'react-router-dom';
import BackgroundAnimaiton from './components/BackgroundAnimaiton';
import NavBar from './components/NavBar';
import InfoHeaderPanel from './components/InfoHeaderPanel';
import MobileTopBar from './components/MobileTopBar';

function App() {
	return (
		<div className="App scroll-smooth hover:scroll-auto">
			<HashRouter basename="/">
				<main>
					<div className="container mx-auto relative">
						<div className="flex-none md:flex mt-20">
							<MobileTopBar />
							<InfoHeaderPanel />
							<MainContainer />
							<NavBar />
						</div>
					</div>
				</main>
				<BackgroundAnimaiton />
			</HashRouter>
		</div>
	);
}

export default App;
