import React from 'react';
import { motion } from 'framer-motion';
import { pageTransition, pageVariants } from '../helpers/pageTransition';
import { MdOutlineImportantDevices } from 'react-icons/md';
import { BiCog } from 'react-icons/bi';
import me from '../assets/me.png';

const About: React.FC = () => {
  return (
    <motion.div initial="out" animate="in" exit="out" variants={pageVariants} transition={pageTransition}>
      <div className='text-left text-white m-9'>
        <p className='text-4xl pb-2'>About <span className='text-sky-700'>Me</span></p>
        <div className="grid md:grid-cols-2 gap-10">
          <div>
            <p className="py-2">My name is Eddie, a full-stack developer located in Sweden. I mostly develop cross-platform web-based applications, but also enjoy building traditional desktop applications or just pure backends.</p>
            <p className="py-2">On my spare time, I love to experiment with new programming techniques, learning a new language or working on my never-ending project ideas backlog. One could say that I'm always experimenting, learning, and never getting bored.</p>
            <p className="py-2">My place in the world is at the intersection of logic and creativity. All projects are problems to be solved, whether it’s related to code or design. My process is iterative; I approach each problem as a learning experience.</p>
          </div>
          <div className='hidden md:block'>
            <img src={me} alt='Me' className='object-fit w-[250px] h-[250px] rounded-[32px] mb-2 drop-shadow-lg' />
            <p className='text-sky-700 font-bold pb-2'>Age: <span className='text-white'>23</span></p>
            <p className='text-sky-700 font-bold pb-2'>Residence: <span className='text-white'>Sweden</span></p>
            <p className='text-sky-700 font-bold pb-2'>Email: <span className='text-white'>eddie.gustafsson@edgus.dev</span></p>
          </div>
        </div>
        <p className='text-2xl py-1'>What <span className='text-sky-700'>I Do</span></p>
        <div className="grid md:grid-cols-2 gap-20">
          <div>
            <MdOutlineImportantDevices className='text-sky-700 text-4xl mb-2' />
            <p className='text-white font-bold text-lg'>Frontend</p>
            <p className='text-white'>I bring a unique combination of technical expertise and creative flair to every project I work on. With a deep understanding of how to create responsive websites and apps that deliver an exceptional user experience, I am well-equipped to help your business achieve its goals through innovative digital solutions.</p>
          </div>
          <div>
            <BiCog className='text-sky-700 text-4xl mb-2' />
            <p className='text-white font-bold text-lg'>Backend</p>
            <p className='text-white'>As a skilled and versatile programmer, I bring a wealth of experience in scripting languages and database systems. My expertise in .Net, C#, Java, Python, and various databases makes me a valuable asset in developing robust and scalable backends.</p>
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default About;
